import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEO } from "src/components/seo"
import { Carousel } from "src/components/carousel"
import { Browser } from "src/components/device"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  Cell,
  HorizontalRuler,
  Headline,
  Paragraph,
  HeadlineHero,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      simple_elements_title: string
      simple_elements_copy: string
      simple_elements_2_copy: string
      stories_title: string
      stories_copy: string
      giving_title: string
      giving_copy: string
      beyond_digital_title: string
      beyond_digital_copy: string
      expertise: string
    }
    images: {
      hero_slide_01: any
      hero_slide_02: any
      hero_slide_03: any
      hero_slide_04: any
      hero_slide_05: any
      mobile_comps: any
      grid_1_logo_construction_static: any
      grid_1_typography_static: any
      grid_1_color_static: any
      grid_2_circles_static: any
      grid_2_donate_static: any
      grid_2_photo_01: any
      grid_2_photo_02: any
      grid_2_photo_03: any
      grid_2_photo_04: any
      grid_2_data_static: any
      grid_3_website_01: any
      grid_3_website_02: any
      grid_3_website_03: any
      grid_3_website_04: any
      grid_3_website_05: any
      grid_3_website_06: any
      grid_3_website_07: any
      grid_3_website_08: any
      grid_3_website_09: any
      grid_3_page_scroll_static: any
      grid_3_gallery_scroll_static: any
      grid_4_donation_form_static: any
      grid_4_donation_components_1_static: any
      grid_4_donation_components_2_static: any
      grid_5_cover_01: any
      grid_5_cover_02: any
      grid_5_cover_03: any
      grid_5_mailer_static: any
      grid_5_report_01: any
      grid_5_report_02: any
      grid_5_report_03: any
      grid_5_report_04: any
      grid_5_report_05: any
      grid_5_tote_static: any
      grid_5_envelope_static: any
    }
    videos: {
      grid_1_logo_construction: any
      grid_2_circles: any
      grid_2_donate: any
      grid_2_data: any
      grid_3_page_scroll: any
      grid_3_gallery_scroll: any

    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>
      
      <Container bleed>
        <Block>
          <Carousel
              images={[
                images.hero_slide_01,
                images.hero_slide_02,
                images.hero_slide_03,
                images.hero_slide_04,
                images.hero_slide_05,
              ]}
            />
        </Block>
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Paragraph>{copy.intro}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container bleed>
        <Block>
          <ImgFluid image={images.mobile_comps} />
        </Block>
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.simple_elements_title}</Headline>
          <Paragraph>{copy.simple_elements_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
            >
              <VideoLoopResponsive
                ratio="$16/9"
                video={videos.grid_1_logo_construction}
                image={images.grid_1_logo_construction_static}
              />
            </Col>
            <Col>
              <ImgFluid image={images.grid_1_typography_static} />
            </Col>
            <Col>
              <ImgFluid image={images.grid_1_color_static} />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <Paragraph>{copy.simple_elements_2_copy}</Paragraph>
      </Container>

      <Container>
        <Block>
          <Grid columns={{ bp1: "$2" }}>
            <Col>
              <VideoLoopResponsive
                ratio="$1/1"
                video={videos.grid_2_circles}
                image={images.grid_2_circles_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio="$1/1"
                video={videos.grid_2_donate}
                image={images.grid_2_donate_static}
              />
            </Col>
            <Col>
              <Carousel
                ratio="$1/1"
                images={[
                  images.grid_2_photo_01,
                  images.grid_2_photo_02,
                  images.grid_2_photo_03,
                  images.grid_2_photo_04,
                ]}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio="$1/1"
                video={videos.grid_2_data}
                image={images.grid_2_data_static}
              />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.stories_title}</Headline>
          <Paragraph>{copy.stories_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
            >
              <Carousel
                ratio="$4/3"
                images={[
                  images.grid_3_website_01,
                  images.grid_3_website_02,
                  images.grid_3_website_03,
                  images.grid_3_website_04,
                  images.grid_3_website_05,
                  images.grid_3_website_06,
                  images.grid_3_website_07,
                  images.grid_3_website_08,
                  images.grid_3_website_09,
                ]}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio="$3/4"
                video={videos.grid_3_page_scroll}
                image={images.grid_3_page_scroll_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio="$3/4"
                video={videos.grid_3_gallery_scroll}
                image={images.grid_3_gallery_scroll_static}
              />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.giving_title}</Headline>
          <Paragraph>{copy.giving_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Grid gap>
              <Col>
                <ImgFluid image={images.grid_4_donation_form_static} />
              </Col>
            </Grid>
            <Grid gap>
              <Col>
                <ImgFluid image={images.grid_4_donation_components_1_static} />
              </Col>
              <Col>
                <ImgFluid image={images.grid_4_donation_components_2_static} />
              </Col>
            </Grid>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.beyond_digital_title}</Headline>
          <Paragraph>{copy.beyond_digital_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col>
              <Carousel
                ratio="$3/4"
                images={[
                  images.grid_5_cover_01,
                  images.grid_5_cover_02,
                  images.grid_5_cover_03,
                ]}
              />
            </Col>
            <Col>
              <ImgFluid image={images.grid_5_mailer_static} />
            </Col>
            <Col
              start={{ bp0: "$1", bp1: "$1" }}
              end={{ bp0: "$2", bp1: "$3" }}
            >
              <Carousel
                images={[
                  images.grid_5_report_01,
                  images.grid_5_report_02,
                  images.grid_5_report_03,
                  images.grid_5_report_04,
                  images.grid_5_report_05,
                ]}
              />
            </Col>
            <Col>
              <ImgFluid image={images.grid_5_tote_static} />
            </Col>
            <Col>
              <ImgFluid image={images.grid_5_envelope_static} />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
    </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "gpp" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        simple_elements_title
        simple_elements_copy
        simple_elements_2_copy
        stories_title
        stories_copy
        giving_title
        giving_copy
        beyond_digital_title
        beyond_digital_copy
        expertise
      }
      images {
        hero_slide_01 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        hero_slide_02 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        hero_slide_03 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        hero_slide_04 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        hero_slide_05 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        mobile_comps {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_logo_construction_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_typography_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_color_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_circles_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_donate_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_photo_01 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_photo_02 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_photo_03 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_photo_04 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_2_data_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_website_01 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_website_02 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_website_03 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_website_04 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_website_05 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_website_06 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_website_07 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_website_08 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_website_09 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_3_page_scroll_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_3_gallery_scroll_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_4_donation_form_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_4_donation_components_1_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_4_donation_components_2_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_5_cover_01 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_5_cover_02 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_5_cover_03 {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_5_mailer_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_5_report_01 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_5_report_02 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_5_report_03 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_5_report_04 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_5_report_05 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_5_tote_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_5_envelope_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
      }
      videos {
        grid_1_logo_construction {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_2_circles {
          src
        }
        grid_2_donate {
          src
        }
        grid_2_data {
          src
        }
        grid_3_page_scroll {
          src
        }
        grid_3_gallery_scroll {
          src
        }
      }
    }
  }
`