import React, { useEffect, useState, useRef, useMemo, useCallback } from "react"
import { useInView } from "react-intersection-observer"

import { ImgFluid } from "./img-fluid"
import { Ratio } from "src/components/primitives"

export const Carousel = props => {
  const { title = "", ratio = "$16/9", images = [] } = props

  const [inViewRef, inView] = useInView({
    threshold: 0.3,
  })

  const [currentSlide, setCurrentSlide] = useState(0)
  const imagesLoaded = useRef([])
  const requestRef = useRef(0)
  const elapsedRef = useRef(0)
  const fpsInterval = useRef(1000)

  useEffect(() => {
    imagesLoaded.current = [...new Array(images.length)].map(_ => false)
  }, [images.length])

  const nextSlide = useMemo(() => {
    let nextSlide = currentSlide + 1
    if (nextSlide === images.length) {
      nextSlide = 0
    }
    return nextSlide
  }, [currentSlide, images.length])

  const goToNextSlide = useCallback(() => {
    setCurrentSlide(current => {
      if (current === images.length - 1) {
        return 0
      } else {
        return current + 1
      }
    })
  }, [setCurrentSlide, images.length])

  const animate = time => {
    if (!elapsedRef.current) {
      elapsedRef.current = time
      requestRef.current = requestAnimationFrame(animate)
      return
    }

    const timeElapsed = time - elapsedRef.current
    requestRef.current = requestAnimationFrame(animate)

    if (timeElapsed > fpsInterval.current) {
      // console.log(imagesLoaded.current[nextSlide])
      fpsInterval.current = 3000
      elapsedRef.current = time
      goToNextSlide()
    }
  }

  React.useEffect(() => {
    if (inView) {
      requestRef.current = requestAnimationFrame(animate)
    } else {
      fpsInterval.current = 1000
      elapsedRef.current = null
      cancelAnimationFrame(requestRef.current)
    }
    return () => {
      cancelAnimationFrame(requestRef.current)
    }
  }, [inView, animate])

  const onImageLoaded = useCallback(
    ({ idx }) => {
      // console.log("loaded", idx)
      imagesLoaded.current[idx] = true
    },
    [imagesLoaded]
  )

  const renderImages = useMemo(() => {
    return images.map((image, idx) => {
      return idx === currentSlide || idx === nextSlide ? (
        <div
          key={`${idx}`}
          style={{ display: idx !== currentSlide && "none" }}
          role="group"
          aria-roledescription="slide"
          aria-label={`${idx + 1} of ${images.length}`}
        >
          <ImgFluid
            loading="eager"
            image={image}
            onLoad={onImageLoaded}
            onLoadParams={{ idx: idx }}
          />
        </div>
      ) : null
    })
  }, [currentSlide, nextSlide, images])

  return (
    <>
      <Ratio
        ratio={ratio}
        ref={inViewRef}
        style={{ overflow: "hidden" }}
        role="region"
        as="section"
        aria-roledescription="carousel"
        aria-label={title}
      >
        <div aria-live="off">{renderImages}</div>
      </Ratio>
    </>
  )
}
